<template>
<v-card elevation="0">
    <v-card-text>
    <v-data-table :headers="headers"
        :items="documents"
        sort-by="_id"
        :sort-desc="true"
        class="elevation-1"
    >
        <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title>Users</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field v-model="keyword" placeholder="Cari..." hide-details></v-text-field>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog scrollable v-model="dialog" max-width="700px">
                <!-- <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                        New Item
                    </v-btn>
                </template> -->
                <v-card>
                    <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <pre>{{editedItem}}</pre>
                                <!-- <v-textarea rows="10" v-model="editedItem"></v-textarea> -->
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="viewItem(item)">
                mdi-eye-outline
            </v-icon>
            <v-icon small class="mr-2" @click="editItem(item)">
                mdi-handshake-outline
            </v-icon>
        </template>
        <template v-slot:[`item._id`]="{ item }">
          {{convvertId2Date(item._id)}}
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          <span :title="item.phone">
            {{item.phone?String(item.phone).substring(0,20):''}}
          </span>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <span :title="item.email">
            {{item.email?String(item.email).substring(0,30):''}}
          </span>
        </template>
        <template v-slot:[`item.lastActive`]="{ item }">
          <span :title="item.lastActive">
            <!-- {{item.lastActive? item.lastActive :'' | moment}}  -->
            {{String(item.lastActive).substring(0,19).replace('T', ' ') | moment}} 
          </span>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span :title="item.name">
            {{item.name?String(item.name).substring(0,30):''}}
          </span>
        </template>
        <template v-slot:[`item.fcmToken`]="{ item }">
          <span :title="item.fcmToken">
          {{item.fcmToken?String(item.fcmToken).substring(0,10):''}}
          </span>
        </template>
        <template v-slot:[`item.vendorCode`]="{ item }">
          <span :title="item.vendorCode">
          {{item.vendorCode?String(item.vendorCode).substring(0,20):''}}
          </span>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="vendorDialog" width="500">
      <v-card class="py-3">
        <v-card-title>
          Vendor
        </v-card-title>
        <v-card-text class="py-4">
            <v-autocomplete clearable filled rounded dense :items="vendors" item-text="name" item-value="code" v-model="editedItem.vendorCode" hide-details="auto"></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="save()">
            Save
          </v-btn>
          <v-btn text color="primary" @click="vendorDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</v-card>
</template>

<script>
  import moment from 'moment'
  // const short = require('short-uuid');
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Tgl Register', value: '_id' },
        { text: 'Email', value: 'email' },
        { text: 'Nama', value: 'name' },
        { text: 'Last Active', value: 'lastActive' },
        { text: 'Version', value: 'appVersion' },
        { text: 'Via', value: 'via' },
        { text: 'FCM', value: 'fcmToken' },
        { text: 'Vendor', value: 'vendorCode' },
        { text: 'Action', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        _id: '',
        vendorCode: '',
      },
      defaultItem: {
        _id: '',
        code: '',
        name: '',
        content: '',
      },
      documents: [],
      keyword: '',
      vendorDialog: false,
      vendorCode: null,
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'View Item'
      },
      vendors() {
          return this.$store.state.vendor.objs
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      keyword(val) {
        if (!val) val = ''
        this.documents = this.$store.state.user.objs.filter((el) => {
          return el.name.toLowerCase().includes(val.toLowerCase()) ||
              el.email.toLowerCase().includes(val.toLowerCase())
        })
      }
    },

    created () {
      this.initialize()
      this.$store.dispatch('vendor/getObjs')
    },
    filters: {
        moment: function (date) {
            if (!date || date=='undefined') return ''
            return moment(date).format('D-MMM-YYYY HH:mm:ss');
        }
    },

    methods: {
      convvertId2Date(_id) {
        let dd = moment(new Date(parseInt(_id.substring(0, 8), 16) * 1000)).utcOffset('+0700').format('D-MMM-YYYY HH:mm:ss');
        dd = dd.substring(0,19).replace('T', ' ')
        return dd
      },
      initialize () {
        this.$store.dispatch('user/getObjs').then(response => {
          console.log(response)
            this.documents = response
        })
      },

      editItem (item) {
        this.editedIndex = this.documents.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.vendorDialog = true
      },

      viewItem (item) {
        this.editedIndex = this.documents.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.documents.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.documents.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        // this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.documents[this.editedIndex], this.editedItem)
        } else {
          // delete this.editedItem._id
          // this.documents.push(this.editedItem)
        }
        console.log(this.editedItem)
        const payload = {
          _id: this.editedItem._id,
          payload: {
            vendorCode: this.editedItem.vendorCode
          }
        }
        
        this.$store.dispatch('user/postObj', payload).then(() => {
            console.log('response')
            this.vendorDialog = false
            // this.close()
            // this.initialize()
        }, err => {
            console.log('error', err)
            this.vendorDialog = false
        })
      },
      
    },
  }
</script>