var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.documents,"sort-by":"_id","sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Users")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"placeholder":"Cari...","hide-details":""},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"scrollable":"","max-width":"700px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('pre',[_vm._v(_vm._s(_vm.editedItem))])])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Close ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" mdi-eye-outline ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-handshake-outline ")])]}},{key:"item._id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convvertId2Date(item._id))+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.phone}},[_vm._v(" "+_vm._s(item.phone?String(item.phone).substring(0,20):'')+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.email}},[_vm._v(" "+_vm._s(item.email?String(item.email).substring(0,30):'')+" ")])]}},{key:"item.lastActive",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.lastActive}},[_vm._v(" "+_vm._s(_vm._f("moment")(String(item.lastActive).substring(0,19).replace('T', ' ')))+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name?String(item.name).substring(0,30):'')+" ")])]}},{key:"item.fcmToken",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.fcmToken}},[_vm._v(" "+_vm._s(item.fcmToken?String(item.fcmToken).substring(0,10):'')+" ")])]}},{key:"item.vendorCode",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.vendorCode}},[_vm._v(" "+_vm._s(item.vendorCode?String(item.vendorCode).substring(0,20):'')+" ")])]}}],null,true)})],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.vendorDialog),callback:function ($$v) {_vm.vendorDialog=$$v},expression:"vendorDialog"}},[_c('v-card',{staticClass:"py-3"},[_c('v-card-title',[_vm._v(" Vendor ")]),_c('v-card-text',{staticClass:"py-4"},[_c('v-autocomplete',{attrs:{"clearable":"","filled":"","rounded":"","dense":"","items":_vm.vendors,"item-text":"name","item-value":"code","hide-details":"auto"},model:{value:(_vm.editedItem.vendorCode),callback:function ($$v) {_vm.$set(_vm.editedItem, "vendorCode", $$v)},expression:"editedItem.vendorCode"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.vendorDialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }